import Vue from "vue";
import * as mutation from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import * as subscriptions from "@/graphql/subscriptions";
import VueCookie from "vue-cookie";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { Notice } from "view-design";
import { SET_CURRENT_USER, UPDATE_CURRENT_USER, NEW_STATE, SET_AGENT } from "@/store/actions.type";
import { cloneDeep, get } from "lodash";
import { PRESIGNED_S3_METHODS } from "@connectpath/common";
import { getSignedS3URL } from "@/graphql/queries";
import NeedHelp from "@/assets/alert_1.mp3";
import { state } from "../agent.module";
import store from "@/store";
import { i18n } from "../../plugins/language";

import { DateTime } from "luxon";
import { fetcherAmplify } from "@/libs/fetcher";
import { smartdialcore } from "../../common/services/smart-dial/smartdial.js";
import { IDLE_UPDATE_RESTRICTED_STATUSES, SECURITY_PROFILES } from "@connectpath/common";
import { getTimeObject } from "@/utils/time/getTimeObject";
import { INSTANCE_LOGO_PATH, LANGUAGE, PROMISE, REALM, THEME } from "@connectpath/common-frontend";
import { ForbiddenError } from "@/Errors/ForbidenAccessError";

const SMD = new smartdialcore();

let updateUsersList = [];

setInterval(() => {
  if (updateUsersList.length === 0) return;

  let arrayUsed = JSON.parse(JSON.stringify(updateUsersList));

  store.commit("users/updateUser", arrayUsed, { root: 1 });
  store.commit("team/updateTeam", arrayUsed, { root: 1 });
  store.commit("chat/updateUserSelected", arrayUsed, { root: 1 });

  updateUsersList = [];
}, 3000);

window.onunload = () => {
  if (window.intervalUpdateUser) {
    clearInterval(window.intervalUpdateUser);
  }
};

function getQueueName(arn, rootState) {
  for (let x = 0; x < rootState.agent.queuetags.length; x++) {
    if (arn === rootState.agent.queuetags[x].queueARN) {
      return rootState.agent.queuetags[x].name;
    }
  }
}

function omitDeep(obj, key) {
  if (Array.isArray(obj)) return omitDeepArrayWalk(obj, key);
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === "object" && val !== null) newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
}

function omitDeepArrayWalk(arr, key) {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === "object") return omitDeep(val, key);
    return val;
  });
}

function isQueueExist(arn, rootState) {
  for (let x = 0; x < rootState.agent.queuetags.length; x++) {
    if (arn === rootState.agent.queuetags[x].queueARN) {
      return true;
    }
  }
  return false;
}

function renderNoticeTag(h, tags, rootState) {
  let list = [];

  if (!tags) {
    list.push(
      h(
        "Tag",
        {
          class: ["ivu-tag-blue"],
          prop: {
            bordered: true,
          },
        },
        ["None"]
      )
    );
    return list;
  }

  for (let x = 0; x < tags.length; x++) {
    if (!isQueueExist(tags[x], rootState)) continue;
    list.push(
      h(
        "Tag",
        {
          class: ["ivu-tag-blue"],
          prop: {
            bordered: true,
          },
        },
        [getQueueName(tags[x], rootState)]
      )
    );
  }

  return list;
}

export default {
  namespaced: true,
  state: {
    instanceTimezone: {
      abbr: "",
      isdst: true,
      offset: 0,
      text: "",
      value: "",
    },
    clockOffset: 0,
    adminLogoUrl: null,
    logoMediaURL: null,
    CognitoPoolId: "",
    agentOnCall: "",
    securityProfiles: {},
    routingProfiles: {},
    account: {},
    profile: {
      Roles: [],
    },
    postAnnouncement: false,
    newNotif: false,
    announcements: {
      listAnnouncements: {
        items: [],
      },
    },
    tasks: [],
    loadingTasks: true,
    securityProfile: {
      Name: null,
      Description: null,
      SoftPhone: null,
      Realm: null,
    },
    currentSecurityProfile: {
      Name: null,
      Description: null,
      PermissionGroups: [],
      Realm: null,
      SoftPhone: "dextr",
    },
    statusAfterContactWork: "",
    currentRoutingProfile: null,
  },
  getters: {
    getClockOffset(state) {
      return state.clockOffset;
    },
    acwTimeLimit() {
      return state.currentUser?.profile?.PhoneConfig?.AfterContactWorkTimeLimit;
    },
    userRole(state) {
      return state.profile.Roles;
    },
    agentStatus(state) {
      return {
        name: state.profile.StatusName,
        stateStartTimestamp: state.profile.StatusStartTimestamp,
      };
    },
    listSecurityProfiles(state) {
      return state.securityProfiles;
    },
    instanceAccount(state) {
      return state.account;
    },
    getAdminLogoUrl(state) {
      return state.adminLogoUrl;
    },
    getLogoMediaURL(state) {
      return state.logoMediaURL;
    },
    userInstanceTimezone(state) {
      return state.instanceTimezone;
    },
    instanceTimezoneOffset(state) {
      if (state.instanceTimezone.offset >= 0 && state.instanceTimezone.isdst) {
        return state.instanceTimezone.offset + 1;
      } else if (state.instanceTimezone.offset < 0 && state.instanceTimezone.isdst) {
        return state.instanceTimezone.offset - 1;
      } else {
        return state.instanceTimezone.offset;
      }
    },
    isShowDrawer(state) {
      return state.postAnnouncement;
    },
    onNewNotif(state) {
      return state.newNotif;
    },
    isUserAnInstanceAdmin(state) {
      if (state.profile && state.profile.Username && state.profile.Username.includes("IAM@")) return true;
      return false;
    },
    getProfileData(state) {
      return state.profile || {};
    },
    isAllowPostAnnouncement(state) {
      if (state.profile && state.profile.Roles && state.profile.Roles.indexOf("supervisors") > -1) {
        return true;
      }
      if (state.profile && state.profile.Roles && state.profile.Roles.indexOf("admins") > -1) {
        return true;
      }
      if (state.profile && state.profile.Roles && state.profile.Roles.indexOf("instance-admins") > -1) {
        return true;
      }

      return false;
    },
    getUserTasks(state) {
      return { loading: state.loadingTasks, tasks: state.tasks || [] };
    },
    instanceId() {
      return state.currentUser?.profile?.InstanceId || "";
    },
    getAllRoutingProfiles(state) {
      return state.routingProfiles?.items || [];
    },
    getUsername(state) {
      return state.profile?.Username || "";
    },
    getRoutingProfilesList(state) {
      let results = [];
      if (state.routingProfiles?.items) {
        const routeProfiles = new Set();
        for (const profile of state.routingProfiles.items) {
          const { Arn, Id, Name } = profile;
          const routeKey = `${Arn}_${Id}_${Name}`;
          routeProfiles.add(routeKey);
        }
        results = Array.from(routeProfiles).map((routeKey) => {
          const [Arn, Id, Name] = routeKey.split("_");
          return { Arn, Id, Name };
        });
      }
      return results;
    },
    securityProfileName(state) {
      return state.securityProfile.Name || SECURITY_PROFILES.INSTANCE_ADMIN;
    },
    isTheSecurityProfileAdmin(state, getters) {
      return (
        getters.securityProfileName === SECURITY_PROFILES.DEXTR_ADMIN ||
        getters.securityProfileName === SECURITY_PROFILES.INSTANCE_ADMIN
      );
    },
    getDashboardSecurityGroup(state) {
      if (state.currentSecurityProfile.PermissionGroups.length) {
        return state.currentSecurityProfile.PermissionGroups.find((item) => item.Name === "Dashboard");
      }
      return null;
    },
    getStatusAfterContactWork(state) {
      return state.statusAfterContactWork;
    },
    listSecurityGroup(state) {
      return state.securityProfiles.items;
    },
    getSecurityProfile: (state) => state.securityProfile,
    getMediaConcurrencies: (state) => {
      return state.account?.RoutingProfile?.MediaConcurrencies;
    },
    getCurrentSecurityProfile: (state) => state.currentSecurityProfile,
    getCustomerBucketName: (state) => state.account?.BucketName,
  },
  mutations: {
    setClockOffset(state, payload) {
      state.clockOffset = payload;
    },
    setLocalState(state, v) {
      state.profile.StatusName = v.name;
      state.profile.StatusStartTimestamp = v.startTimestamp
        ? new Date(v.startTimestamp).toISOString()
        : new Date(getTimeObject().offsetGetTime()).toISOString();
    },
    setAdminLogoUrl(state, payload) {
      state.adminLogoUrl = payload;
    },
    setLogoMediaURL(state, payload) {
      state.logoMediaURL = payload;
    },
    updateSecurityProfileData(state, v) {
      state.profile.SecurityProfile = v;
    },
    setSecurityProfile(state, v) {
      state.securityProfile = v;
    },
    setCurrentSecurityProfile(state, v) {
      state.currentSecurityProfile = v;
    },
    resetCurrentSecurityProfile(state) {
      state.currentSecurityProfile = {
        Name: null,
        Description: null,
        PermissionGroups: [],
        Realm: null,
        SoftPhone: "dextr",
      };
    },
    setCurrentSecurityProfileName(state, v) {
      state.currentSecurityProfile.Name = v;
    },
    setCurrentSecurityProfileDescription(state, v) {
      state.currentSecurityProfile.Description = v;
    },
    setCurrentSecurityProfileRealm(state, v) {
      state.currentSecurityProfile.Realm = v;
    },
    setCurrentSecurityProfileSoftPhone(state, v) {
      state.currentSecurityProfile.SoftPhone = v;
    },
    setInstanceAccount(state, v) {
      state.account = {
        ...v,
      };
    },
    setInstanceTimezone(state, v) {
      state.instanceTimezone.text = v.text || v._tz_text;
      state.instanceTimezone.value = v.value || v._tz_value;
    },
    setNewNotif(state, v) {
      state.newNotif = v;
    },
    setCurrentUser(state, payload) {
      let data = payload;
      if (payload?.Contacts && payload?.Contacts[0]?.State === "CONNECTED_ONHOLD") {
        data.StatusName = "Hold";
        data.StatusStartTimestamp = payload?.Contacts[0]?.StateStartTimestamp;
      }
      state.profile = { ...state.profile, ...data };
    },
    setShowDrawer(state, payload) {
      state.postAnnouncement = payload;
      if (payload) state.newNotif = false;
    },
    setTasks(state, tasks) {
      state.tasks = tasks;
    },
    setSecurityProfiles(state, v, isNew) {
      if (!isNew) {
        state.securityProfiles = v;
      } else {
        state.securityProfiles.items.push(v);
      }
    },
    setRoutingProfiles(state, v, isNew) {
      if (!isNew) {
        state.routingProfiles = v;
      } else {
        state.routingProfiles.items.push(v);
      }
      state.routingProfiles.items.sort((a, b) => {
        const nameA = a.Name.toUpperCase();
        const nameB = b.Name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    setLoadingTasks(state, value) {
      state.loadingTasks = value;
    },
    updateSecurityProfiles(state, payload) {
      for (let index = 0; index < state.securityProfiles.items.length; index++) {
        const element = state.securityProfiles.items[index];

        if (payload.id === element.id) {
          element.PermissionGroups = payload.PermissionGroups;
        }
      }
    },
    setStatusAfterContactWork(state, agentStatus) {
      state.statusAfterContactWork = agentStatus;
    },
    updateState(state, value) {
      state.instanceTimezone = value.instanceTimezone;
      state.securityProfiles = value.securityProfiles;
      state.adminLogoUrl = value.adminLogoUrl;
      state.profile = value.profile;
      state.postAnnouncement = value.postAnnouncement;
      state.newNotif = value.newNotif;
      state.announcements = value.announcements;
      state.securityProfile = value.securityProfile;
      state.currentSecurityProfile = value.currentSecurityProfile;
    },
  },
  actions: {
    async fetchClockOffset({ commit }) {
      try {
        let serverTime = await fetcherAmplify().graphql({ query: queries.getCurrentGmtEpochTimeStamp });
        let delay = new Date().getTime() - serverTime.getCurrentGmtEpochTimeStamp;
        commit("setClockOffset", delay);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    setLocalStateAction({ commit }, payload) {
      commit("setLocalState", {
        name: payload.name,
        startTimestamp: payload.startTimestamp,
      });
    },
    async hydrateApp({ dispatch }, user) {
      try {
        await dispatch("setCurrentUserData", { userData: user });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getNetworkTraversal() {
      const res = await API.graphql(graphqlOperation(queries.getNetworkTraversal));
      return res.data.getNetworkTraversal;
    },
    signout() {
      try {
        if (!window.location.origin) {
          window.location.origin =
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "");
        }

        if (VueCookie.get("dextr_subdomain")) {
          window.location.href = window.location.origin + "/" + VueCookie.get("dextr_subdomain");
          return false;
        }
        window.location.href = window.location.origin;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteSecurityProfiles(context, payload) {
      const { deleteSecurityProfile } = await fetcherAmplify().graphql({
        query: mutation.deleteSecurityProfile,
        variables: {
          input: {
            id: payload.data.id,
            InstanceId: payload.InstanceId,
          },
        },
      });
      for (let x = 0; x < context.state.securityProfiles.items.length; x++) {
        if (deleteSecurityProfile.id == context.state.securityProfiles.items[x].id) {
          context.state.securityProfiles.items.splice(x, 1);
          break;
        }
      }
    },
    async getTasks(context, { InstanceId, Username }) {
      context.commit("setLoadingTasks", true);
      let res = await API.graphql(
        graphqlOperation(queries.getTasks, {
          InstanceId,
          Username,
        })
      );

      context.commit("setLoadingTasks", false);
      context.commit("setTasks", res.data.getUser.Tasks);
      return res.data.getUser.Tasks;
    },
    async getSecurityProfiles(context, payload) {
      const res = await API.graphql(
        graphqlOperation(queries.listSecurityProfiles, {
          InstanceId: payload,
        })
      );

      let response = res.data.listSecurityProfiles;
      response.items.forEach((item) => {
        item.PermissionGroups.forEach((element) => {
          if (element.Name === "Users and Permissions") {
            element.PermissionItems = element.PermissionItems.filter((perItem) => perItem.Name !== "Global");
            element.PermissionItems.forEach((permissionItem) => {
              if (permissionItem.Name === "Global Contact") {
                permissionItem.Values.Create = null;
                permissionItem.Values.View = null;
              }
            });
          }
        });
      });
      context.commit("setSecurityProfiles", response, false);
      return res;
    },
    async getRoutingProfiles(context, payload) {
      try {
        let res = await API.graphql(
          graphqlOperation(queries.listRoutingProfiles, {
            InstanceId: payload,
          })
        );
        context.commit("setRoutingProfiles", res.data.listRoutingProfiles);
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async createSecurityProfile(context, { profile, instanceId }) {
      const res = await API.graphql(
        graphqlOperation(mutation.createSecurityProfile, {
          input: {
            InstanceId: instanceId,
            ...profile,
          },
        })
      );
      context.state.securityProfiles.items.push(res.data.createSecurityProfile);
      return res;
    },
    async updateSecurityProfile(context, payload) {
      delete payload.data._index;
      delete payload.data._rowKey;

      const res = await API.graphql(
        graphqlOperation(mutation.updateSecurityProfile, {
          input: {
            InstanceId: payload.InstanceId,
            ...payload.data,
          },
        })
      ).catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
    },
    updateStateSecurityProfiles({ commit }, payload) {
      commit("updateSecurityProfiles", payload);
    },
    async setCurrentUserData({ dispatch }, { userData }) {
      try {
        await dispatch("fetchAmazonConnectData", { userData });
        await dispatch("fetchApplicationData", { userData });
        await dispatch("applyDataInApplication", { userData });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async applyDataInApplication({ dispatch }, { userData }) {
      try {
        await dispatch("setUserDataApplication", { userData });
        await dispatch("applyUserPreferences", { userData });
        await dispatch("applyAccountData", { userData });
        await dispatch("handleSubscriptions", { userData });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    setUserDataApplication({ commit, rootGetters }, { userData }) {
      try {
        commit(SET_CURRENT_USER, userData, {
          root: 1,
        });
        commit("setCurrentUser", userData);
        commit(
          "logs/setLogger",
          {
            alias: userData.InstanceAlias || userData.Alias,
            username: userData.Username,
            region: userData.Region || rootGetters?.agent?.Region,
            instance_id: userData.InstanceId,
            rtc_enabled: window.useConnectRTC,
          },
          { root: true }
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async applyUserPreferences({ dispatch, commit, rootGetters, state }, { userData }) {
      try {
        dispatch("hid/registerSupportedDevice", null, { root: 1 });
        const isDarkMode = userData.Preferences.Theme === THEME.DARK;
        const language = userData.Preferences.Language || LANGUAGE.ENGLISH;
        commit("settings/setDarkMode", isDarkMode, { root: 1 });
        commit("settings/setLanguage", language, { root: 1 });
        window.localStorage.setItem("language", language);

        if (userData.Username.includes("IAM@")) {
          let supreme = rootGetters["securityProfile/getInstanceAdmin"];

          dispatch("SET_CURRENT_USER_RULES", supreme, {
            root: 1,
          });
        } else {
          await dispatch("getSecurityProfileById", {
            InstanceId: userData.InstanceId,
            id: userData.SecurityProfileId,
            ConnectProfiles: userData.SecurityProfileIds,
          }).then((res) => {
            commit("setSecurityProfile", res.getSecurityProfile);
            dispatch("SET_CURRENT_USER_RULES", res.getSecurityProfile, {
              root: 1,
            });
          });
        }
        commit("contacts/setFavoriteContacts", userData.FavoriteDirectoryIds || [], { root: 1 });

        dispatch("channel/listEmailContacts", state.tasks, {
          root: 1,
        });

        let favoriteMembers = get(userData, "FavoriteTeamMembers", []) || [];
        commit("team/addNewFavoriteUser", favoriteMembers, {
          root: true,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async applyAccountData({ commit, dispatch, state }, { userData }) {
      try {
        const res = await API.graphql(
          graphqlOperation(queries.getAccount, {
            InstanceId: userData.InstanceId,
          })
        );
        commit("setInstanceAccount", res.data.getAccount);

        if (!SMD.isInitialized() && res.data.getAccount.CognitoPoolId) {
          SMD.init(Vue.prototype.$agent);
        }

        const { AdminLogo, LogoMediaURL } = res.data.getAccount;

        if (AdminLogo) {
          Storage.get(AdminLogo, {
            level: "public",
          }).then((pic) => {
            commit("setAdminLogoUrl", pic);
          });
        } else {
          commit("setAdminLogoUrl", null);
        }
        if (LogoMediaURL) {
          try {
            const URL = await dispatch("fetchLogoMediaURL");
            commit("setLogoMediaURL", URL[PRESIGNED_S3_METHODS.GET]);
          } catch (error) {
            console.error("Error fetching logo pre-signed url", error);
          }
        } else {
          commit("setLogoMediaURL", null);
        }

        if (state.securityProfile.Realm === REALM.INSTANCE || state.profile.Username.includes("IAM@")) {
          commit("setQueueTags", res.data.getAccount.Queues, {
            root: true,
          });
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    handleSubscriptions({ commit, dispatch }, { userData }) {
      try {
        const { InstanceId, Username, SecurityProfileId } = userData;

        dispatch("livelook/onUpdateLivelookContacts", InstanceId, {
          root: true,
        });
        const userSessionPayload = userData.session.idToken.payload;

        commit("setInstanceTimezone", userSessionPayload);
        dispatch("settings/settingsSubs", InstanceId, {
          root: 1,
        });
        dispatch("settings/dispositionSubs", InstanceId, {
          root: 1,
        });
        dispatch("contacts/globalContactsSub", InstanceId, {
          root: 1,
        });
        dispatch("contacts/deleteGlobalContactsSub", InstanceId, {
          root: 1,
        });
        if (SecurityProfileId) {
          dispatch("onUpdateSecurityProfile", {
            InstanceId: InstanceId,
            id: SecurityProfileId,
          });
        }
        dispatch(
          "chat/onNewAgentChatParticipantEvent",
          {
            InstanceId,
            Username,
          },
          { root: 1 }
        );
        dispatch("reportSubs", {
          InstanceId,
          Owner: Username,
        });
        dispatch("createUserSubs", { InstanceId });
        dispatch("deleteUserSubs", { InstanceId });
        dispatch("deleteAnnouncementSubs", {
          InstanceId,
        });
        dispatch("updateUsersSubs", { InstanceId });
        dispatch("updateUserSubs", { InstanceId });
        dispatch("updateUserSecuritySubs", {
          InstanceId,
          Username,
        });
        dispatch("onLoginSubs", {
          InstanceId,
          Username,
        });

        dispatch("onNewAnnouncementSubs", {
          InstanceId,
          userData,
        });
        dispatch(
          "channel/onNewEmailContact",
          {
            InstanceId,
            Username,
          },
          { root: 1 }
        );
        dispatch("onNeedsHelp", { InstanceId });

        dispatch("newActivitySubs", {
          Username,
          InstanceId,
        });
        dispatch("newActivityFromPeer", {
          CustomerEndpoint: Username,
          InstanceId,
        });
        dispatch("integrationsSubs", { instanceId: InstanceId });
        dispatch("integrationsDeletedSubs", { instanceId: InstanceId });
        dispatch(
          "phone/onNewSmsContact",
          {
            InstanceId,
            Username,
          },
          { root: 1 }
        );
        window.Broadcast.$emit("hydratingAppSetCurrentUserDone");
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async onSendHelp(context, payload) {
      let api = context.rootState.api.api;
      let p = cloneDeep(api.getUser);

      p.NeedsHelp = Object.prototype.hasOwnProperty.call(api.getUser, "NeedsHelp") ? !api.getUser.NeedsHelp : true;

      let dataSend = {
        InstanceId: p.InstanceId,
        Username: p.Username,
        NeedsHelp: p.NeedsHelp,
      };

      if (payload) {
        dataSend.NeedsHelp = payload.needHelp;
        dataSend.Username = payload.userName;
      } else {
        context.commit("needHelp", p.NeedsHelp, { root: true });
      }

      let input = cloneDeep(dataSend);
      const res = await API.graphql(
        graphqlOperation(mutation.needsHelp, {
          input,
        })
      );
      if (res.data.needsHelp.Username == p.Username) {
        context.commit("needHelp", p.NeedsHelp, { root: true });
      }
      return res;
    },
    async onUpdateCurrentUser(context, payload) {
      let input = cloneDeep(payload);
      delete input.Password;
      delete input.Groups;
      delete input.Queues;
      delete input.Region;

      const res = await API.graphql(
        graphqlOperation(mutation.updateUser, {
          input,
        })
      );
      context.commit(UPDATE_CURRENT_USER, res.data.updateUser, {
        root: true,
      });
      let favoriteMembers = get(res, "data.updateUser.FavoriteTeamMembers", []) || [];
      context.commit("team/addNewFavoriteUser", favoriteMembers, {
        root: true,
      });
      return res;
    },
    createUserSubs(context, { InstanceId }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onCreateUser, {
          InstanceId,
        })
      );

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onCreateUser;
          context.commit("users/newUser", newData, { root: 1 });
          context.commit("team/newTeamMember", newData, { root: 1 });
          context.dispatch("team/onFetchTeam", { InstanceId }, { root: 1 });
          return Promise.resolve(newData);
        },
      };

      context.commit("addSubscriptionToList", { id: "createUserSubs", observable, handler }, { root: true });
    },
    deleteUserSubs(context, { InstanceId }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onDeleteUser, {
          InstanceId,
        })
      );

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onDeleteUser;
          if (newData.Username === context.rootGetters.agentSummary.Username) {
            VueCookie.set("deletedForceLogout", true, { expires: "1M" });
            context.dispatch("signout");
          } else {
            context.commit("users/deleteUser", newData, { root: 1 });
            context.commit("team/deleteTeamMember", newData, { root: 1 });
            return Promise.resolve(newData);
          }
        },
      };

      context.commit("addSubscriptionToList", { id: "deleteUserSubs", observable, handler }, { root: true });
    },

    reportSubs(context, { InstanceId, Owner }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onFinishedReport, {
          InstanceId,
          Owner,
        })
      );
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onFinishedReport;
          let temp = context.rootState.reports.list.find((element) => element.id === newData.id);
          newData.HideNA = temp?.HideNA;
          if (!newData.Parent) {
            if (newData.Status === "complete") {
              Notice.success({
                title: `${i18n.t("notifications.report")} '${newData.Name}' ${i18n.t("notifications.isFinished")}`,
                desc: i18n.t("notifications.viewItInTheReportsPage"),
                duration: 5,
              });
            }
            if (newData.Status === "error") {
              Notice.error({
                title: `${i18n.t("notifications.report")} '${newData.Name}' ${i18n.t("notifications.failedToComplete")}`,
                desc: newData.Error || i18n.t("notifications.pleaseCheckYourInputsAndTryAgain"),
                duration: 10,
              });
            }
            let tempParent = context.rootState.reports.list.find((element) => element.id === newData.ParentId);
            let temp = tempParent.ChildReports.find((element) => element.id === newData.id);
            newData.HideNA = temp?.HideNA;
          }

          context.commit("reports/updateReport", newData, { root: true });

          return Promise.resolve(newData);
        },
      };

      context.commit("addSubscriptionToList", { id: "reportSubs", observable, handler }, { root: true });
    },
    onUpdateSecurityProfile(context, { InstanceId, id }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onUpdateSecurityProfile, {
          InstanceId,
          id,
        })
      );
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateSecurityProfile;
          context.commit("updateSecurityProfileData", newData);
          context.dispatch("UPDATE_RULES", { profile: newData }, { root: 1 });
          let inputData = {
            InstanceId: context.state.profile.InstanceId,
            Username: context.state.profile.Username,
            Id: context.state.profile.Id,
            SecurityProfile: omitDeep(newData, "__typename"),
          };
          context
            .dispatch("users/onUpdateUser", inputData, {
              root: 1,
            })
            .catch((err) => console.error("err: ", err));

          return Promise.resolve(newData);
        },
      };
      context.commit("addSubscriptionToList", { id: "onUpdateSecurityProfile", observable, handler }, { root: true });
    },
    onNeedsHelp(context, { InstanceId }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onNeedsHelp, {
          InstanceId,
        })
      );
      const handler = {
        next: async (eventData) => {
          let newData = eventData.value.data.onNeedsHelp;

          if (context.state.profile.Id !== newData.Id) {
            if (this._vm.$can("view", "raise_hand") && newData?.NeedsHelp) {
              let user = context.rootGetters["team/team"].filter((user) => user.Username === newData.Username);

              let ding = new Audio(NeedHelp);
              ding.play();

              if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
              } else if (Notification.permission === "'granted'") {
                Vue.prototype.$Notify.create({
                  title: `${user[0].Username} ${i18n.t("notifications.needsHelp")}`,
                });
              } else if (Notification.permission !== "'denied'") {
                const permission = await Notification.requestPermission();
                if (permission === "granted") {
                  Vue.prototype.$Notify.create({
                    title: `${user[0].Username ? user[0].Username : "N/A"} ${i18n.t("notifications.needsHelp")}`,
                  });
                }
              }

              Notice.open({
                title: "",
                name: "announcement-notice-info",
                duration: 15,
                render: (h) => {
                  return h(
                    "i-row",
                    {
                      style: {
                        fontSize: "17px",
                        cursor: "pointer",
                      },
                      on: {
                        click: () => {
                          Notice.close("announcement-notice-info");
                          store.commit("chat/setDrawer", true);
                          store.commit("chat/setUserSelected", user[0]);
                          store.dispatch("team/clearUserUnreadMessageStatus", {
                            Username: user[0].Username,
                          });
                        },
                      },
                    },
                    [
                      h(
                        "i-col",
                        {
                          style: {
                            display: "inline-block",
                          },
                          props: {
                            span: 24,
                          },
                        },
                        [
                          [
                            h(
                              "p",
                              {
                                style: {
                                  marginBottom: "10px",
                                },
                              },
                              [
                                h("i", {
                                  style: {
                                    fontSize: "20px",
                                    color: "#05668d",
                                  },
                                  class: ["fa", "fa-megaphone"],
                                }),
                                ` ${i18n.t("notifications.needsHelp")}`,
                              ]
                            ),

                            h(
                              "p",
                              {
                                class: ["txt-ellipsis"],
                                style: {
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                },
                              },
                              [newData.Title]
                            ),

                            h(
                              "small",
                              {
                                style: {
                                  display: "block",
                                  textDecoration: "underline",
                                },
                              },
                              [newData.Username]
                            ),

                            h(
                              "small",
                              {
                                style: {
                                  display: "block",
                                  color: "#aaa",
                                  marginTop: "10px",
                                },
                              },
                              [
                                h("i-time", {
                                  props: {
                                    time: getTimeObject().getOffsetTime(),
                                  },
                                  style: {
                                    display: "block",
                                  },
                                }),
                              ]
                            ),
                          ],
                        ]
                      ),
                    ]
                  );
                },
              });
            }
          }
          context.commit("team/teamNeedHelp", newData, { root: 1 });
          if (context.state.profile.Id == newData.Id) {
            context.commit("needHelp", newData.NeedsHelp, { root: true });
          }
          return Promise.resolve(newData);
        },
      };
      context.commit("addSubscriptionToList", { id: "onNeedsHelp", observable, handler }, { root: true });
    },
    onLoginSubs(context, { InstanceId, Username }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onLogin, {
          InstanceId,
          Username,
        })
      );
      const handler = {
        next: () => {},
      };
      context.commit("addSubscriptionToList", { id: "onLoginSubs", observable, handler }, { root: true });
    },
    onNewAnnouncementSubs(context, { InstanceId, userData }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onNewAnnouncement, {
          InstanceId,
        })
      );
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onNewAnnouncement;
          context.dispatch("announcement/getList", null, { root: true });
          let annoucementQueues = newData.Queues;
          let agentQueues = [];
          //TODO: refactor this abomination
          for (let x = 0; x < context.rootState.agent.queuetags.length; x++) {
            if (context.rootState.agent.queuetags[x].queueId !== "global") {
              agentQueues.push(context.rootState.agent.queuetags[x].queueARN);
            }
          }

          let shouldDisplay = false;
          if (agentQueues && newData.DeliveryMethods.indexOf("screen_pop") > -1) {
            shouldDisplay = agentQueues.some((queue) => annoucementQueues.includes(queue));
          }

          if (userData.Username === newData.From) {
            return false;
          }

          if (shouldDisplay) {
            context.state.announcements.listAnnouncements.items.unshift(newData);

            let ding = new Audio(NeedHelp);
            context.commit("setNewNotif", true);
            ding.play();

            var length = 500;
            var trimmedString = newData.Message.substring(0, length);
            let msgTruncated = trimmedString + "...";

            if (newData.Urgency === "Emergency") {
              Notice.open({
                title: "",
                name: "announcement-notice-emergency",
                duration: 15,
                render: (h) => {
                  return h(
                    "i-row",
                    {
                      style: {
                        fontSize: "17px",
                      },
                      on: {
                        click: () => {
                          context.commit("setNewNotif", true);
                          Notice.close("announcement-notice-emergency");
                        },
                      },
                    },
                    [
                      h(
                        "i-col",
                        {
                          style: {
                            display: "inline-block",
                          },
                          props: {
                            span: 24,
                          },
                        },
                        [
                          h(
                            "i-col",
                            {
                              style: {
                                display: "inline-block",
                              },
                              props: {
                                span: 20,
                              },
                            },
                            [
                              [
                                h(
                                  "p",
                                  {
                                    style: {
                                      marginBottom: "10px",
                                    },
                                  },
                                  [
                                    h("i", {
                                      style: {
                                        fontSize: "20px",
                                        color: "#05668d",
                                      },
                                      class: ["fa", "fa-exclamation-triangle"],
                                    }),
                                    ` ${i18n.t("common.new")}  ${i18n.t("common.announcement")}`,
                                  ]
                                ),

                                h(
                                  "p",
                                  {
                                    class: ["txt-ellipsis"],
                                    style: {
                                      fontWeight: "bolder",
                                      fontSize: "15px",
                                    },
                                  },
                                  [newData.Title]
                                ),

                                h(
                                  "small",
                                  {
                                    style: {
                                      display: "block",
                                      textDecoration: "underline",
                                    },
                                  },
                                  [newData.From]
                                ),

                                h(
                                  "p",
                                  {
                                    style: {
                                      fontSize: "14px",
                                      wordBreak: "break-all",
                                      textAlign: "justify",
                                      marginTop: "15px",
                                    },
                                  },
                                  [msgTruncated]
                                ),

                                h(
                                  "p",
                                  {
                                    style: {
                                      marginTop: "10px",
                                    },
                                  },
                                  renderNoticeTag(h, newData.Queues, context.rootState)
                                ),

                                h(
                                  "small",
                                  {
                                    style: {
                                      display: "block",
                                      color: "#aaa",
                                    },
                                  },
                                  [
                                    h("p", [
                                      DateTime.fromISO(newData.Date)
                                        .setLocale(i18n.locale === "es" ? "es" : "")
                                        .toRelative(),
                                    ]),
                                  ]
                                ),
                              ],
                            ]
                          ),
                        ]
                      ),
                    ]
                  );
                },
              });
            } else {
              Notice.open({
                title: "",
                name: "announcement-notice-info",
                duration: 15,
                render: (h) => {
                  return h(
                    "i-row",
                    {
                      style: {
                        fontSize: "17px",
                      },
                      on: {
                        click: () => {
                          context.commit("announcement/setSelectedItem", newData, { root: true });
                          context.commit("currentUser/setShowDrawer", !context.getters.isShowDrawer, { root: true });
                          context.commit("setNewNotif", true);
                          Notice.close("announcement-notice-info");
                        },
                      },
                    },
                    [
                      h(
                        "i-col",
                        {
                          style: {
                            display: "inline-block",
                          },
                          props: {
                            span: 24,
                          },
                        },
                        [
                          [
                            h(
                              "p",
                              {
                                style: {
                                  marginBottom: "10px",
                                },
                              },
                              [
                                h("i", {
                                  style: {
                                    fontSize: "20px",
                                    color: "#05668d",
                                  },
                                  class: ["fa", "fa-megaphone"],
                                }),
                                ` ${i18n.t("common.new")}  ${i18n.t("common.announcement")}`,
                              ]
                            ),

                            h(
                              "p",
                              {
                                class: ["txt-ellipsis"],
                                style: {
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                },
                              },
                              [newData.Title]
                            ),

                            h(
                              "small",
                              {
                                style: {
                                  display: "block",
                                  textDecoration: "underline",
                                },
                              },
                              [newData.From]
                            ),

                            h(
                              "p",
                              {
                                style: {
                                  fontSize: "14px",
                                  wordBreak: "break-all",
                                  textAlign: "justify",
                                  marginTop: "15px",
                                },
                              },
                              [msgTruncated]
                            ),

                            h(
                              "p",
                              {
                                style: {
                                  marginTop: "10px",
                                },
                              },
                              renderNoticeTag(h, newData.Queues, context.rootState)
                            ),

                            h(
                              "small",
                              {
                                style: {
                                  display: "block",
                                  color: "#aaa",
                                },
                              },
                              [
                                h("p", [
                                  DateTime.fromISO(newData.Date)
                                    .setLocale(i18n.locale === "es" ? "es" : "")
                                    .toRelative(),
                                ]),
                              ]
                            ),
                          ],
                        ]
                      ),
                    ]
                  );
                },
              });
            }
            return Promise.resolve(newData);
          }
        },
      };
      context.commit("addSubscriptionToList", { id: "onNewAnnouncementSubs", observable, handler }, { root: true });
    },
    async onSaveTimezone(context, payload) {
      const res = await API.graphql(
        graphqlOperation(mutation.updateAccount, {
          input: payload,
        })
      );
      return res.data;
    },

    integrationsSubs({ commit, dispatch }, { instanceId }) {
      const observable = API.graphql(graphqlOperation(subscriptions.onIntegrationChanged, { InstanceId: instanceId }));

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onIntegrationChanged;
          dispatch("integrations/integrationChanged", { instanceId, integration: newData }, { root: true });
          return;
        },
        error: (err) => {
          console.error("Error >  integrationsSubs >  ", err);
        },
      };
      commit(
        "addSubscriptionToList",
        {
          id: "integrationsSubs",
          observable,
          handler,
          linkedQuery: { name: "integrations/listIntegrations", params: { instanceId } },
        },
        { root: true }
      );
    },

    integrationsDeletedSubs({ commit, dispatch }, { instanceId }) {
      const observable = API.graphql(graphqlOperation(subscriptions.onIntegrationDeleted, { InstanceId: instanceId }));
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onIntegrationDeleted;
          dispatch("integrations/integrationDeleted", { integration: newData }, { root: true });
          return;
        },
        error: (err) => {
          console.error("Error >  integrationsDeletedSubs >  ", err);
        },
      };
      commit(
        "addSubscriptionToList",
        {
          id: "integrationsDeletedSubs",
          observable,
          handler,
          linkedQuery: { name: "integrations/listIntegrations", params: { instanceId } },
        },
        { root: true }
      );
    },

    updateUsersSubs(context, { InstanceId }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onUpdateUsers, {
          InstanceId,
        })
      );
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateUsers;

          if (newData?.items?.length > 0) {
            for (let user of newData.items) {
              if (user.Contacts) {
                let contacts = JSON.parse(user.Contacts).filter((contact) => contact.State !== "ERROR");

                user.NumberOfActiveContacts = contacts.length;
                user.Contacts = contacts;
              }

              if (user.Id === context.rootState.currentUser.profile.Id) {
                if (
                  newData?.items?.length > 0 &&
                  newData?.items[0]?.Contacts &&
                  newData?.items[0]?.Contacts[0]?.State === "CONNECTED_ONHOLD"
                ) {
                  newData.items[0].StatusName = "Hold";
                  user.StatusName = "Hold";
                }
                context.commit(UPDATE_CURRENT_USER, newData, {
                  root: true,
                });

                context.commit("needHelp", user.NeedsHelp, { root: 1 });
              }
            }

            newData.items.forEach((item) => {
              updateUsersList.push(item);
            });
            store.dispatch("team/onFetchTeam", { InstanceId }, { root: 1 });
          }
          return;
        },
        error: (err) => {
          console.error(err);
        },
      };
      context.commit(
        "addSubscriptionToList",
        {
          id: "updateUsersSubs",
          observable,
          handler,
          linkedQuery: { name: "team/onFetchTeam", params: { InstanceId } },
        },
        { root: true }
      );
    },

    updateUserSubs(context, { InstanceId }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onUpdateUser, {
          InstanceId,
        })
      );

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateUser;

          if (newData.Id === context.rootState.currentUser.profile.Id) {
            let statusFromAPI = newData.StatusName;
            if (statusFromAPI.includes(";remote")) {
              statusFromAPI = statusFromAPI.replace(";remote", "");
              const state = context.rootGetters.agentStates.filter((state) => {
                return state.name === statusFromAPI;
              })[0];

              if (state) {
                let agent = context.rootState.agent.agent;
                agent.state.name = `Pending${statusFromAPI}`;
                context.commit(SET_AGENT, agent, { root: 1 });
                context.dispatch(NEW_STATE, state, { root: 1 });
              }
            }
            if (
              newData?.items?.length > 0 &&
              newData?.items[0]?.Contacts.length > 0 &&
              newData?.items[0]?.Contacts[0]?.State === "CONNECTED_ONHOLD"
            ) {
              newData.items[0].StatusName = "Hold";
            }
            context.commit(UPDATE_CURRENT_USER, newData, {
              root: true,
            });

            context.dispatch("team/onFetchTeam", { InstanceId }, { root: 1 });

            context.commit("setCurrentUser", newData);
            context.commit("needHelp", newData.NeedsHelp, { root: 1 });
          } else {
            updateUsersList.push(newData);
          }
        },
        error: (err) => {
          console.error(err);
        },
      };
      context.commit(
        "addSubscriptionToList",
        {
          id: "updateUserSubs",
          observable,
          handler,
          linkedQuery: { name: "team/onFetchTeam", params: { InstanceId } },
        },
        { root: true }
      );
    },

    onOfferedMonitor(context, data) {
      try {
        context?.rootGetters["currentUser/getProfileData"]?.Username;
        context.dispatch("team/onInitiatePeer", data, { root: 1 });
      } catch (error) {
        console.error("Error in OfferedMonitor: ", error);
      }
    },
    onOfferedVoiceCall(context, data) {
      try {
        context.dispatch("team/onVoiceCallPeer", data, { root: 1 });
      } catch (error) {
        console.error("Error in onOfferedVoiceCall: ", error);
      }
    },
    onDestroyedVoiceCall(context) {
      try {
        context.dispatch("team/destroyVoiceCall", null, { root: 1 });
        context.dispatch("team/destroyPeer", null, { root: 1 });

        context.state.agentOnCall = "";
      } catch (error) {
        console.error("Error in onDestroyedVoiceCall: ", error);
      }
    },

    onAnsweredMonitor({ dispatch }, data) {
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        messageContext: {
          data,
        },
      };
      try {
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${data.initiator}] [onAnsweredMonitor] - Signaling the peer to establish a direct connection`;
        dispatch("logs/addSocketLog", payload, { root: true });
        window.mPeerInitiator.signal(data.AnswerSDP);
      } catch (error) {
        payload.level = "ERROR";
        payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${data.initiator}] [onAnsweredMonitor] - Error while trying to signal the the peer.`;
        dispatch("logs/addSocketLog", payload, { root: true });
        console.error("Error in AnsweredMonitor: ", error);
      }
    },

    onAnsweredVoiceCall({ context, dispatch }, data) {
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        messageContext: {
          data: data,
        },
      };
      try {
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${data.initiator}] [onAnsweredVoiceCall] - Signaling the peer to establish a direct connection`;
        dispatch("logs/addSocketLog", payload, { root: true });
        window.vPeerInitiator.signal(data.AnswerSDP);
        context.state.agentOnCall = data.callee;
      } catch (error) {
        payload.level = "ERROR";
        payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${payload.messageContext.data.initiator}] [onAnsweredVoiceCall] - Error while trying to signal the the peer.`;
        dispatch("logs/addSocketLog", payload, { root: true });
      }
    },

    updateUserSecuritySubs(context, { InstanceId, Username }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onUpdateUserSecurityProfile, {
          InstanceId,
          Username,
        })
      );
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateUserSecurityProfile;

          if (newData) {
            context.dispatch("UPDATE_RULES", { profile: newData }, { root: 1 });
          }
          return Promise.resolve(newData);
        },
      };

      context.commit("addSubscriptionToList", { id: "updateUserSecuritySubs", observable, handler }, { root: true });
    },
    newActivitySubs(context, { Username, InstanceId }) {
      let payload = {
        Username,
        InstanceId,
      };

      API.graphql(graphqlOperation(subscriptions.onNewActivity, payload));
    },
    newActivityFromPeer(context, { CustomerEndpoint, InstanceId }) {
      let payload = {
        CustomerEndpoint,
        InstanceId,
      };

      const observable = API.graphql(graphqlOperation(subscriptions.onNewActivityCustomer, payload));
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onNewActivity;
          newData["InitiationMethod"] = "INBOUND";

          const tempCustomerEndPoint = newData.CustomerEndpoint;
          const tempUsername = newData.Username;
          newData["CustomerEndpoint"] = tempUsername;
          newData["Username"] = tempCustomerEndPoint;

          context.commit("activity/hangingActivity", newData, { root: 1 });
          return Promise.resolve(newData);
        },
      };

      context.commit("addSubscriptionToList", { id: "newActivityFromPeer", observable, handler }, { root: true });
    },
    async updateAccount(context, payload) {
      let data = {
        input: {
          InstanceId: context.rootGetters["api"].getUser.InstanceId,
          ...payload,
        },
      };

      const removeObjectsWithNull = (obj) => {
        const o = JSON.parse(JSON.stringify(obj));

        Object.keys(o).forEach((key) => {
          if (o[key] && typeof o[key] === "object") o[key] = removeObjectsWithNull(o[key]);
          else if (
            (o[key] === undefined || o[key] === "" || o[key] === null) &&
            key !== "AdminLogo" &&
            key !== "LogoMediaURL" &&
            key !== "CognitoPoolId"
          )
            delete o[key];
        });

        return o;
      };

      delete data.input.Username;

      const res = await API.graphql(graphqlOperation(mutation.updateAccount, removeObjectsWithNull(data)));
      context.commit("setInstanceAccount", res.data.updateAccount);
      if (res.data.updateAccount.LogoMediaURL) {
        context.commit("setLogoMediaURL", res.data.updateAccount.LogoMediaURL);
      } else {
        context.commit("setLogoMediaURL", null);
      }
      return res;
    },
    async onNewAnnouncement(context, payload) {
      const res = await API.graphql(
        graphqlOperation(mutation.createAnnouncement, {
          input: payload,
        })
      );
      return res;
    },
    async listOfAnnouncement({ state }, payload) {
      const res = await API.graphql(graphqlOperation(queries.listAnnouncements, payload));
      state.announcements = res.data;
      return res;
    },
    async getSecurityProfileById(context, payload) {
      const res = await API.graphql(graphqlOperation(queries.getSecurityProfile, payload));
      return res.data;
    },
    async deleteAnnouncement(context, payload) {
      const res = await API.graphql(
        graphqlOperation(mutation.deleteAnnouncement, {
          input: payload,
        })
      );
      return res;
    },
    deleteAnnouncementSubs(context, { InstanceId }) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onDeletedAnnouncement, {
          InstanceId,
        })
      );
      const handler = {
        next: () => {
          context.dispatch("announcement/getList", null, { root: true });
        },
      };
      context.commit("addSubscriptionToList", { id: "deleteAnnouncementSubs", observable, handler }, { root: true });
    },
    updateCurrentSecurityProfileSoftPhone(context, ccp) {
      context.commit("setCurrentSecurityProfileSoftPhone", ccp);
    },

    setAgentStatus({ commit, dispatch }, { agentStatus }) {
      commit("setLocalState", agentStatus);
      dispatch(NEW_STATE, agentStatus, { root: 1 });
    },

    setAgentStatusByIdle({ dispatch, rootGetters }, { idleAgentStatusName }) {
      const currentAgentStatus = rootGetters.agentStatus;
      const idleAgentStatus = rootGetters.agentStates.find((agentState) => agentState.name === idleAgentStatusName);
      if (!IDLE_UPDATE_RESTRICTED_STATUSES.includes(currentAgentStatus.name) && idleAgentStatus) {
        dispatch("setAgentStatus", { agentStatus: idleAgentStatus });
      }
    },
    updateStatusAfterContactWork({ commit }, { agentStatus }) {
      commit("setStatusAfterContactWork", agentStatus);
    },
    async uploadMediaLogoToS3(context, { URL, image }) {
      const response = await fetch(URL[PRESIGNED_S3_METHODS.PUT], {
        method: PRESIGNED_S3_METHODS.PUT,
        headers: {
          "Content-Type": "image/jpg",
        },
        body: image,
      });

      if (response.status === 403) {
        throw new ForbiddenError({ status: response.status, message: "Access Forbidden" });
      }

      return response;
    },
    async fetchLogoMediaURL({ getters }) {
      const customerBucket = getters.getCustomerBucketName;

      if (!customerBucket) {
        throw new Error("Customer bucket not found");
      }

      const {
        getSignedS3URL: { items },
      } = await fetcherAmplify().graphql({
        query: getSignedS3URL,
        variables: {
          bucketName: customerBucket,
          key: INSTANCE_LOGO_PATH,
          method: PRESIGNED_S3_METHODS.ALL,
          contentType: "image/jpg",
        },
      });

      if (!items?.length) {
        throw new Error("Logo media URL not found");
      }

      const logoMediaURL = items.reduce((acc, item) => {
        acc[item.Method] = item.Url;
        return acc;
      }, {});

      return logoMediaURL;
    },
    async fetchAmazonConnectData({ dispatch }, { userData }) {
      try {
        const getSecurityProfilesPromise = dispatch("getSecurityProfiles", userData.InstanceId);
        const getRoutingProfilesPromise = dispatch("getRoutingProfiles", userData.InstanceId);
        const promises = [getRoutingProfilesPromise, getSecurityProfilesPromise];

        const promiseResults = await Promise.allSettled(promises);
        const errors = promiseResults.filter((result) => result.status === PROMISE.REJECTED);
        if (errors.length > 0) {
          errors.forEach((error) => {
            throw error.reason;
          });
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async fetchApplicationData({ dispatch }, { userData }) {
      try {
        const getConfigPromise = dispatch("settings/getConfigs", userData.InstanceId, { root: 1 });
        const getTasksPromise = dispatch("getTasks", userData);
        const getTeamPromise = dispatch("team/onFetchTeam", { InstanceId: userData.InstanceId }, { root: 1 });
        const getIntegrationsPromise = dispatch(
          "integrations/listIntegrations",
          { instanceId: userData.InstanceId },
          { root: true }
        );
        const getUsersPromise = dispatch("users/onFetchUsers", { InstanceId: userData.InstanceId }, { root: true });
        const getAnnouncementsPromise = dispatch("announcement/getList", userData.InstanceId, { root: 1 });
        const getHealthPromise = dispatch("health/fetchHealth", null, { root: 1 });
        const getChatConversationsPromise = dispatch(
          "chat/onFetchAgentChatConversations",
          { InstanceId: userData.InstanceId, Participants: [userData.Username], Username: userData.Username },
          { root: 1 }
        );
        const getContactsPromise = dispatch(
          "contacts/onFetchContacts",
          { InstanceId: userData.InstanceId, UserId: userData.Id },
          { root: 1 }
        );
        const getCurrentGmtEpochTimeStampPromise = dispatch("fetchClockOffset");

        const promises = [
          getConfigPromise,
          getTasksPromise,
          getTeamPromise,
          getIntegrationsPromise,
          getUsersPromise,
          getAnnouncementsPromise,
          getHealthPromise,
          getChatConversationsPromise,
          getContactsPromise,
          getCurrentGmtEpochTimeStampPromise,
        ];

        const promiseResults = await Promise.allSettled(promises);

        const errors = promiseResults.filter((result) => result.status === PROMISE.REJECTED);
        if (errors.length > 0) {
          errors.forEach((error) => {
            console.error(error.reason);
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
